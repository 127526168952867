import { getAuthHeader } from "@src/app/superagent";
import { environmentConfig } from "@src/appV2/environment";
import { QualificationsResponse } from "@src/appV2/Qualifications/types";
import { logApiFailureEvent } from "@src/lib/analytics/src";
import { ILicensesCount } from "@src/lib/interface/src";
import request from "superagent";

import { ICreateLicense, ILicenseDetails } from "./interfaces";

const licenseManagerUrl = environmentConfig.REACT_APP_LICENSE_MANAGER_SERVICE_URL;

export const getLicensesCount = async (workerId: string): Promise<ILicensesCount> => {
  const url = `${licenseManagerUrl}/workers/${workerId}/licenses/count`;
  return await request
    .get(url)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body);
};
export const getLicenses = async (workerId: string): Promise<ILicenseDetails[]> => {
  const url = `${licenseManagerUrl}/workers/${workerId}/licenses`;
  return await request
    .get(url)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body);
};

export const softDeleteLicense = async (
  licenseId: string,
  workerId: string
): Promise<{ success: true }> => {
  return await request
    .patch(`${licenseManagerUrl}/workers/${workerId}/licenses/${licenseId}`)
    .send({ status: "ARCHIVED" })
    .set(await getAuthHeader())
    .then(({ body }) => body);
};

export const addLicense = async (
  license: ICreateLicense,
  workerId: string,
  shouldThrowError = false
): Promise<ILicenseDetails> => {
  // removing empty strings
  return await request
    .post(`${licenseManagerUrl}/workers/${workerId}/licenses`)
    .set(await getAuthHeader())
    .send(license)
    .then(({ body }) => body)
    .catch((err) => logApiFailureEvent(err, shouldThrowError));
};

export const getQualifications = async (
  shouldThrowError = false
): Promise<QualificationsResponse> => {
  const url = `${environmentConfig.REACT_APP_BASE_API_URL}/qualifications?enabled=true`;
  return await request
    .get(url)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => {
      return body;
    })
    .catch((err) => logApiFailureEvent(err, shouldThrowError));
};
